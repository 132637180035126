@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap");
@media (min-width: 992px) {
  .brand {
    background-color: #1a1a27;
    box-shadow: none; }
    .brand .btn i {
      color: #494b74; }
    .brand .btn .svg-icon svg g [fill] {
      transition: fill 0.3s ease;
      fill: #494b74; }
    .brand .btn .svg-icon svg:hover g [fill] {
      transition: fill 0.3s ease; }
    .brand .btn.active .svg-icon svg g [fill], .brand .btn:hover .svg-icon svg g [fill] {
      transition: fill 0.3s ease;
      fill: #335882; }
    .brand .btn.active .svg-icon svg:hover g [fill], .brand .btn:hover .svg-icon svg:hover g [fill] {
      transition: fill 0.3s ease; }
    .brand .btn.active i, .brand .btn:hover i {
      color: #335882; } }

@media (max-width: 991.98px) {
  .header-mobile {
    background-color: #1a1a27; }
    .header-mobile .burger-icon span {
      background-color: #484f66; }
      .header-mobile .burger-icon span::before, .header-mobile .burger-icon span::after {
        background-color: #484f66; }
    .header-mobile .burger-icon:hover span {
      background-color: #335882; }
      .header-mobile .burger-icon:hover span::before, .header-mobile .burger-icon:hover span::after {
        background-color: #335882; }
    .header-mobile .burger-icon-active span {
      background-color: #335882; }
      .header-mobile .burger-icon-active span::before, .header-mobile .burger-icon-active span::after {
        background-color: #335882; }
    .header-mobile .btn i {
      color: #484f66; }
    .header-mobile .btn .svg-icon svg g [fill] {
      transition: fill 0.3s ease;
      fill: #484f66; }
    .header-mobile .btn .svg-icon svg:hover g [fill] {
      transition: fill 0.3s ease; }
    .header-mobile .btn.active .svg-icon svg g [fill], .header-mobile .btn:hover .svg-icon svg g [fill] {
      transition: fill 0.3s ease;
      fill: #335882; }
    .header-mobile .btn.active .svg-icon svg:hover g [fill], .header-mobile .btn:hover .svg-icon svg:hover g [fill] {
      transition: fill 0.3s ease; }
    .header-mobile .btn.active i, .header-mobile .btn:hover i {
      color: #335882; } }
